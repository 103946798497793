import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./App.css";

function App() {
  const [mood, setMood] = useState("normal");
  const [moodNumber, setMoodNumber] = useState(65);
  const [audio, setAudio] = useState();

  const backgroundColors = {
    jaymo: "#6A3D3D",
    depressed: "#A896B7",
    normal: "#2864FF",
    alex: "#FF06D7",
    happy: "#06FF2E",
  };

  useEffect(() => {
    setAudio([
      new Audio("guitar.mp3"),
      new Audio("laptop.mp3"),
      new Audio("tax.mp3"),
      new Audio("brawl.mp3"),
      new Audio("mcdonalds.mp3"),
    ]);
  }, []);

  const updateMood = (changeAmount) => {
    let newMood = moodNumber + changeAmount;

    if (newMood < 0) newMood = 0;
    if (newMood > 100) newMood = 100;

    setMoodNumber(newMood);

    if (newMood < 25) {
      setMood("jaymo");
    } else if (newMood < 45) {
      setMood("depressed");
    } else if (newMood < 70) {
      setMood("normal");
    } else if (newMood < 90) {
      setMood("alex");
    } else {
      setMood("happy");
    }
  };

  return (
    <div className="app">
      <div className="title">
        <h1>alex is</h1>
        <h1 className={"title-mood " + mood}>
          {mood == "happy" ? "happy :)" : mood}
        </h1>
      </div>
      <div className="bar">
        <motion.div
          className="bar-progress"
          animate={{
            width: `${moodNumber}%`,
            background: backgroundColors[mood],
          }}
          transition={{
            type: "spring",
            stiffness: 1000,
            damping: 15,
          }}
        />
      </div>
      <div className="full-body">
        <img className="face" src={`alex-${mood}.png`} />
        <img className="body" src="body.png" />
      </div>
      <div className="buttons">
        <motion.div
          whileTap={{ scale: 0.66 }}
          className="buttons-red"
          onClick={() => {
            updateMood(6);
            audio[0].currentTime = 0;
            audio[0].play();

            audio[1].pause();
            audio[2].pause();
            audio[3].pause();
            audio[4].pause();
          }}
        >
          <img src="guitar.png" />
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.66 }}
          className="buttons-yellow"
          onClick={() => {
            updateMood(12);
            audio[1].currentTime = 0;
            audio[1].play();

            audio[0].pause();
            audio[2].pause();
            audio[3].pause();
            audio[4].pause();
          }}
        >
          <img src="laptop.png" />
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.66 }}
          className="buttons-green"
          onClick={() => {
            updateMood(-15);
            audio[2].currentTime = 0;
            audio[2].play();

            audio[0].pause();
            audio[1].pause();
            audio[3].pause();
            audio[4].pause();
          }}
        >
          <img src="tax.png" />
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.66 }}
          className="buttons-blue"
          onClick={() => {
            updateMood(8);
            audio[3].currentTime = 0;
            audio[3].play();

            audio[0].pause();
            audio[1].pause();
            audio[2].pause();
            audio[4].pause();
          }}
        >
          <img src="brawl.png" />
        </motion.div>
        <motion.div
          whileTap={{ scale: 0.66 }}
          className="buttons-purple"
          onClick={() => {
            updateMood(4);
            audio[4].currentTime = 0;
            audio[4].play();

            audio[0].pause();
            audio[1].pause();
            audio[2].pause();
            audio[3].pause();
          }}
        >
          <img src="mcdonalds.png" />
        </motion.div>
      </div>
    </div>
  );
}

export default App;
